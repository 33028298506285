<script setup lang="ts">
import type { NovaBoxMainSectionProps } from './NovaBoxMainSection.types'

withDefaults(defineProps<NovaBoxMainSectionProps>(), {
  title: '',
})

const slots = useSlots()
</script>

<template>
  <div class="box-main-section">
    <div class="box-main-section-header">
      <h4 class="title">{{ title }}</h4>

      <div v-if="slots['tools']" class="tools">
        <slot name="tools" />
      </div>
    </div>

    <div class="box-main-section-contents">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.box-main-section {
  display: flex;
  flex-direction: column;

  @include mobile {
    padding: 0 15px;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 12px;

    > .title {
      font-size: 20px;
      font-weight: 700;
      color: $color-black;
      line-height: 1;
      @include mobile {
        font-size: 18px;
      }
    }
    > .tools {
      display: flex;
      align-items: center;
      gap: 4px;
      ::v-deep(.btn-simple) {
        > .label {
          font-size: 15px;
          color: #767676;
          @include mobile {
            font-size: 14px;
          }
        }
      }
    }
  }

  &-contents {
  }
}
</style>
