<script setup lang="ts">
import type { NovaBoxTopBannerProps } from './NovaBoxTopBanner.types'

const props = defineProps<NovaBoxTopBannerProps>()

const { gtEvent } = useGoogleTag()
const topBannerRef = ref<HTMLElement>()

const handleOnGoLink = () => {
  gtEvent('clickEvent', {
    eventCategory: '클릭',
    eventAction: '배너 링크 클릭',
    eventLabel: '',
    eventSlot: `${props.gaSlot}`,
    eventI18nAddr: '',
    eventComponent: 'NovaBoxTopBanner',
    ...props.source,
  })
  if (props.source.bannerLinkAt === 'Y')
    useNavigations({
      external: props.source.bannerExtrlLinkAt === 'Y',
      url: `/${props.source.bannerLinkUrl}`,
    })
}
</script>

<template>
  <button
    ref="topBannerRef"
    type="button"
    class="top-banner-item"
    @click="handleOnGoLink"
  >
    <NovaImageContainer
      :ratio="'2:1'"
      :image-url="source.bannerMobileImageUrl"
      class="mobile-img"
    />
    <NovaImageContainer
      :ratio="'5:2'"
      :image-url="source.bannerImageUrl"
      class="desktop-img"
    />
    <!--
    <picture>
      <source media="(min-width:768px)" :srcset="source.bannerImageUrl" />
      <NovaImageContainer
        :responsive-size="{ desktop: '4:1', mobile: '2:1' }"
        :image-url="source.bannerMobileImageUrl"
      />
    </picture>-->
  </button>
</template>

<style lang="scss" scoped>
.top-banner-item {
  width: 100%;
  height: 100%;
  .mobile-img {
    display: none;
    @include mobile {
      display: flex;
    }
  }
  .desktop-img {
    display: flex;
    @include mobile {
      display: none;
    }
  }
}
</style>
